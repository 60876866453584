import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { BodyText } from '@/theme/OwnGlobalStylesComponents/StyleText';

interface ProductButtonProps {
  imageIndex: boolean;
}

export const ContainerGeneralHomeHero = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: '5.62rem',
  paddingTop: '2.9rem',
  [theme.breakpoints.down('md')]: {
    minHeight: '0',
    marginBottom: '3.43rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

export const InfoContain = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const ImageContain = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#F5F5F5',
  width: '50%',
  paddingBottom: '10rem',
  [theme.breakpoints.down('md')]: {
    paddingBottom: '0',
    width: '100%',
  },
  '&& img': {
    position: 'relative',
    zIndex: '2',
  },
}));

export const TextContain = styled(Box)(({ theme }) => ({
  width: '50%',
  paddingLeft: '3rem',
  marginTop: '6.82rem',
  [theme.breakpoints.down('md')]: {
    width: '80%',
    marginTop: '1.5rem',
    paddingLeft: '0',
  },
}));

export const Leyend = styled(BodyText)(() => ({
  color: '#85C440',
  marginBottom: '0.5rem',
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  marginTop: '-10rem',
  position: 'relative',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    marginTop: '3rem',
    left: '0',
  },
}));

export const ProductButton = styled(Button)<ProductButtonProps>(({ theme, imageIndex }) => ({
  borderRadius: '0.5rem 0 0.5rem 0.5rem',
  padding: '1rem 3rem',
  backgroundColor: imageIndex ? 'white' : theme.palette.primary.main,
  border: ' 1px solid #D3D3D3',
  boxShadow: '-8px 8px 12px #0000000D',
  [theme.breakpoints.down('md')]: {
    padding: '0.5rem 0.8rem',
  },
}));

export const ProductButtonsContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    margin: '0 auto',
    width: '90%',
    justifyContent: 'center',
  },
}));

export const P = styled('p')(({ theme }) => ({
  position: 'absolute',
  fontSize: '360px',
  top: '-265px',
  right: '0',
  zIndex: '0',
  fontWeight: 'bold',
  color: 'white',
}));

export const ButtonContainer = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'left ',
  gap: '1rem',
  marginTop: '1.44rem',
}));
